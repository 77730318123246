import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';

type PrivateRouteProps = {
  component: any;
  exact: boolean;
  path: string | Array<string>;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const location = useLocation();
  const { auth } = useSelector((state: ReduxRootState) => state);
  const profileCompleteStatus = JSON.parse(localStorage.getItem('profile-complete') || '{}')

  return (  <Route
    {...rest}
    render={props => {
      if (auth.isAuthenticated === true && profileCompleteStatus !== 'confirmed-token') {
        return <Component {...props} />;
      }
      if (profileCompleteStatus === 'confirmed-token') {
        return <Redirect to="/signup" />;
      }
      return <Redirect to={{ pathname: '/', state: { from: location } }} />;
    }}
    />)
  }

// export default connect(null, null, null, {
//   pure: false,
// })(PrivateRoute);

export default PrivateRoute;