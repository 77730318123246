import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { forgotPasswordConfirmation } from '../../Actions/authActions';

const ResetPasswordVerification: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch: any = useDispatch();
  const inviteToken = location.pathname.split('/')[3]

  useEffect(() => {
    dispatch(forgotPasswordConfirmation(inviteToken, history, (message) => {
      history.push('/reset_password', { resetMessage: message });
    }));
    
    // eslint-disable-next-line
  }, [])


  return (
    <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
      <h3>Checking Link Validity...</h3>
    </div>
  );
};

export default ResetPasswordVerification;
